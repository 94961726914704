import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { authReducer } from './slices/auth-slice';
import { quizReducer } from './slices/quiz-slice';
import loadingStatusReducer from './slices/loader-slice';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export interface RootState {
  authReducer: any;  
  quizReducer: any;  
  loadingStatusReducer: any;
}

// Define the persisted state type
export interface PersistedAppState  {
  authReducer: any;
  quizRedecuer: any;
}

const reducer: any = combineReducers({
  authReducer,
  quizReducer,
  loadingStatusReducer,
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['authReducer', "quizReducer"]
}
const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production"
});
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppRootState = RootState & PersistedAppState;
let persistor = persistStore(store);

export { store, persistor };
