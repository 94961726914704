import { FORGOT_PASSWORD } from "route/paths";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;
// API routes
const USER = "user";
const QUIZ = "quiz";

// API endpoints
export const API = {
    USER_LOGIN: `${API_BASE_URL}/${USER}/login`,
    USER_LOGOUT: `${API_BASE_URL}/${USER}/logout`,
    USER_REGISTER: `${API_BASE_URL}/${USER}/register`,
    FORGOT_PASSWORD: `${API_BASE_URL}/${USER}/forgot-password`,
    RESET_PASSWORD: `${API_BASE_URL}/${USER}/reset-password`,
    ADD_QUIZ: `${API_BASE_URL}/${QUIZ}/add`,
    GET_QUIZ: `${API_BASE_URL}/${QUIZ}/get-quiz`,
    GET_SAMPLE_QUIZ: `${API_BASE_URL}/${QUIZ}/get-sample-quiz`,
    SUBMIT_QUIZ: `${API_BASE_URL}/${QUIZ}/submit-quiz`,
};