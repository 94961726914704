import { useState, useEffect, FC } from 'react';

interface CountdownProgressProps {
  initialTime: number;
  questionNumber: number;
  onSecondsChange: (seconds: number) => void;
  onTimeElapsed: () => void;
}

const CountdownProgress: FC<CountdownProgressProps> = ({ initialTime, questionNumber, onSecondsChange, onTimeElapsed }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [progress, setProgress] = useState(100);
  const radius = 15; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle

  useEffect(() => {
    setTimeLeft(initialTime);
  }, [questionNumber, initialTime]);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeElapsed();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime - 1;
        return newTime >= 0 ? newTime : 0;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    setProgress((timeLeft / initialTime) * 100);
    onSecondsChange(timeLeft);
  }, [timeLeft, initialTime]);

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg width="50px" height="50px" viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r={radius}
        stroke={timeLeft > initialTime / 3 ? "#01a03e" : timeLeft > 10 ? "#e8ab03" : "#cc4a43"}
        strokeWidth="3"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        cx="25"
        cy="25"
        r={radius}
        stroke="#e0e0e0"
        strokeWidth="3.5"
        fill="none"
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={circumference - strokeDashoffset}
        style={{
          transform: "rotate(-90deg)", // Start from top (0 degrees)
          transformOrigin: "center",
          transition: "stroke-dashoffset 1s ease",
        }}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dx="0px"
        dy="4px"
        style={{
          fill: timeLeft > initialTime / 3 ? "#01a03e" : timeLeft > 10 ? "#e8ab03" : "#cc4a43",
          fontSize: '11px',
          fontWeight: '600',
        }}
      >
        {timeLeft}
      </text>
    </svg>
  );
};

export default CountdownProgress;
