import { FC, useState } from "react";
import './Login.css';
import { registerUser } from "store/services/auth-service";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "hooks/CustomHooks";
import Alert, { AlertType } from "components/Alert/Alert";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "route/paths";
import ShowIcon from 'assets/icons/show.png';
import HideIcon from 'assets/icons/hide.png';

interface SignUpProps {
    onSignInClick: () => void;
}

const payload = {
    username: "",
    password: "",
    email: "",
    mobileNumber: ""
};
const SignUp: FC<SignUpProps> = ({ onSignInClick }) => {
    const [formData, setFormData] = useState<any>(() => payload);
    const [error, setError] = useState<string>("");
    const [alert, setAlert] = useState<any>({ show: false, title: "", message: "" });
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let error: string = ""
        if (!formData.username?.trim()) {
            error = "Username is missing";
            if (!formData.email?.trim()) {
                error = "Username and email are missing";
                if (!formData.password?.trim()) {
                    error = "Username, email  and password are missing";
                }
            }

        }
        else if (!formData.email) {
            error = "Email is missing";
            if (!formData.password?.trim()) {
                error = "Email and password are missing";
            }
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            error = "Email address is invalid";
            if (!formData.password?.trim()) {
                error = "Email is invalid and password is missing";
            }

        }
        else if (!formData.password) {
            error = "Password is missing";
        } else if (formData.password.length < 6) {
            error = "Password must be at least 6 characters";
        }
        else {
            error = "";
        }
        setError(error);
        return error;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError.length === 0) {
            dispatch(registerUser(formData, (response) => {
                if (response.success === false) {
                    setAlert({ show: true, title: "Failed", message: response.message });
                } else {
                    setAlert({ show: true, title: "Success", message: "Signed up Successfully, You can login with your credentials now" });
                }
            }, (error) => {
                setAlert({ show: true, title: "Failed", message: error.message });
            }
            ))
        } else {

        }
    };
    const handleOkClick = () => {
        if (alert.title === "Success") {
            setFormData(payload);
            onSignInClick();
        }
        setAlert({ show: false, title: "", message: "" });
    }

    return (
        <div className="sign-in-content">
            <form className="sign-in-form" onSubmit={handleSubmit} >
                <h2>New User Registration</h2>
                {error?.length > 0 && <div className="input-validation-error">{error}</div>}
                <input className="sign-in-input"
                    placeholder="Full Name"
                    name="username"
                    type="text"
                    value={formData.username}
                    onChange={handleChange}
                />

                <input className="sign-in-input"
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <div className="password-input-container">
                    <input className="sign-in-password-input"
                        placeholder="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <button type="button" className="show-hide-button"
                        onClick={() => setShowPassword(!showPassword)}>
                        <img src={showPassword ? HideIcon : ShowIcon} alt="visible" />
                    </button>
                </div>

                <input className="sign-in-input"
                    placeholder="Mobile Number [Optional]"
                    name="mobileNumber"
                    type="text"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                />
                <button className="sign-in-button" type="submit">Submit</button>
            </form>
            <div className="sign-in-bottom-container">
                Already have an account?
                <button className="link-button sign" onClick={onSignInClick}>Sign In</button>
            </div>
            {alert.show && <Alert isOpen={true} title={alert.title} message={alert.message}
                type={AlertType.OK}
                onClose={handleOkClick} />}
        </div>
    );
};
export default SignUp;