import { FC, useEffect, useState } from "react";
import { useAppSelector } from "hooks/CustomHooks";
import { AppRootState } from "store";
import './Loader.css';

interface LoaderProps {
    loaderText?: string;
}
const keys = ['get-quiz','get-sample-quiz', 'submit-quiz', 'login', 'logout', 'register', 'forgot-password', 'reset-password',]
const Loader: FC<LoaderProps> = ({ loaderText }) => {
    const loadingStatus = useAppSelector((state: AppRootState) => state.loadingStatusReducer);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    useEffect(() => {
        let currentKey = "";
        if (keys) {
            for (let key of keys) {
                if (loadingStatus[key] === true) {
                    currentKey = key;
                    break;
                }
            }
        }
        else {
            currentKey = 'default';
        }
        setShowLoader(loadingStatus[currentKey]);
    }, [loadingStatus]);

    return <>
        {showLoader ?
            <div className="loader-overlay">
                <div className="loader-box">
                    <span className="loader"></span>
                    {/* <img src={Logo} alt="Logo" className="logo" /> */}
                    <span className="loader-text">{loaderText || 'Loading ...'}</span>
                </div>
            </div> : <></>
        }
    </>
}
export default Loader;