import { FC, useEffect, useMemo, useRef, useState } from "react";
import Option from "./Option";
import CountdownProgress from "components/CountDownProgress/CountdownProgress";

interface QuestionProps {
    question: any;
    totalQuestions: number;
    onTimerChange: (seconds: number) => void,
    onQuestionAnswered: (options: IOption[]) => void,
    onTimeElapsed: () => void,
}
export enum IDifficultyLevel {
    Low = 1,
    Medium = 2,
    High = 3,
}
interface IQuestion {
    id: string,
    title: any,
    order: number,
    category: any,
    answerId: string,
    options: IOption[];
    difficultyLevel: IDifficultyLevel;
}
interface IOption {
    id: string,
    order: number,
    title: string,
    isMarkedAsAnswer: boolean
}
const Question: FC<QuestionProps> = ({ question, totalQuestions, onTimerChange,
    onQuestionAnswered,
    onTimeElapsed }) => {
    const [options, setOptions] = useState<any[]>([]);
    const [showCounter, setShowCounter] = useState<boolean>(() => false);

    const onOptionChange = (index: number, selected: boolean) => {
        let answers: IOption[] = [...options];
        answers = answers.map((option: IOption) => {
            return { ...option, isMarkedAsAnswer: option.order === (index + 1) ? selected : false }
        })
        setOptions([...answers]);
        onQuestionAnswered(answers)
    }
    const getInitialTime = useMemo(() => {
        let initialTime: number = question?.difficultyLevel === 1 ? 60 : 60 + (question?.difficultyLevel - 1) * 30;

        return initialTime;

    }, [question?.difficultyLevel]);

    useEffect(() => {
        if (question) {
            const options = question.options?.map((option: IOption) => {
                return { ...option, isMarkedAsAnswer: option.id === question.answers?.id };
            })
            setOptions(options);
            setShowCounter(false);
            setTimeout(() => {
                setShowCounter(true);
            }, 300);
        }
    }, [question]);

    const onSecondsChange = (seconds: number) => {
        if (seconds > 0) {
            onTimerChange(getInitialTime - seconds)
        }
    }
    const onQuestionTimeElapsed = () => {
        onTimeElapsed();
    }

    return (<div className={`question-outer-container`}>
        <div className="question-header">
            <label className="question-label">Question {question?.order} of {totalQuestions} </label>
            {showCounter && <CountdownProgress questionNumber={question?.order} initialTime={getInitialTime} onSecondsChange={onSecondsChange}
                onTimeElapsed={onQuestionTimeElapsed} />}
        </div>
        <div className="question-body">
            <div className="question-container">
                <p dangerouslySetInnerHTML={{ __html: question?.title }} onSelect={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}></p>
            </div>
            <label>Options</label>
            <div className="answers-container">
                {
                    options && [...options]?.map((option: IOption, index) => {
                        return <Option key={option.id} data={option} questionNumber={question?.order} index={index}
                            handleOptionChange={onOptionChange} />
                    })
                }

            </div>
        </div>
    </div>

    );
}

export default Question;
