import React, { FC, ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';
import { title } from 'process';

interface ModalProps {
    isOpen: boolean;
    title?: string;
    footer?: ReactNode;
    children: any;
    onClose?: () => void;
}
const Modal: FC<ModalProps> = ({ isOpen, title, children, footer, onClose }) => {
    if (!isOpen) return null;
    let modalParent: any;
    if (document.getElementById('modal-root')) {
        modalParent = document.getElementById('modal-root');
    }
    return ReactDOM.createPortal(
        <div className='portal-overlay' onClick={onClose}>
            <div className="portal-container" onClick={(e) => e.stopPropagation()}>
                {title ? <div className='modal-header'>
                    <label className='modal-title'>{title}</label>
                    <button className='portal-close-button white' onClick={onClose}>
                        &times;
                    </button>
                </div> : <button className='portal-close-button' onClick={onClose}>
                    &times;
                </button>
                }
                <div className='modal-content'>
                    {children}
                </div>
                {footer && <div className='modal-footer'>
                    {footer}
                </div>
                }
            </div>
        </div>,
        modalParent
    );
};

export default Modal;
