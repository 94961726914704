import { FC, useState } from "react";
import './Login.css';
import { loginUser } from "store/services/auth-service";
import { useAppDispatch } from "hooks/CustomHooks";
import { FORGOT_PASSWORD } from "route/paths";
import { setIsAuthenticated } from "store/slices/auth-slice";
import { setToken } from "common/Helper";
import { useNavigate } from "react-router-dom";
import ShowIcon from 'assets/icons/show.png';
import HideIcon from 'assets/icons/hide.png';

interface SignInProps {
    onSignUpClick: () => void;
    onForgotPasswordClick: () => void;
}
const SignIn: FC<SignInProps> = ({ onSignUpClick,onForgotPasswordClick }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleEmailChange = (e: any) => setEmail(e.target.value);
    const handleBlur = (e: any) => {

    }
    const handlePasswordChange = (e: any) => setPassword(e.target.value);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Email and password are required.");
            return;
        }
        setError("");
        let userCredentials: UserCredentials = {
            email: email,
            password: password
        };

        dispatch(
            loginUser(
                userCredentials,
                (user) => {
                    if (user?.token?.length) {
                        setToken(user.token);
                        navigate(`/user/${user.username}/home`);
                        setTimeout(() => {
                            dispatch(setIsAuthenticated(true));
                        }, 0);
                    } else {
                        setError('Email or password is incorrect');
                        dispatch(setIsAuthenticated(false));
                    }
                },
                () => {
                    setError('Email or password is incorrect');
                    dispatch(setIsAuthenticated(false));
                }
            )
        );
    };
    const handleForgotPassword = () => {
        navigate(FORGOT_PASSWORD);
    };

    return (
        <div className="sign-in-content">
            <>
                <form className="sign-in-form" onSubmit={handleSubmit} >
                    <h2>Login</h2>
                    {error && <p className="input-validation-error">{error}</p>}
                    <input className="sign-in-input"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={handleBlur}
                    />
                    <div className="password-input-container">
                        <input className="sign-in-password-input"
                            placeholder="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <button type="button" className="show-hide-button"
                            onClick={() => setShowPassword(!showPassword)}>
                            <img src={showPassword ? HideIcon : ShowIcon} alt="visible" />
                        </button>
                    </div>

                    <div className="forgot-password-container">
                        <button className="link-button forgot" onClick={onForgotPasswordClick}>Forgot Password ?</button>
                    </div>
                    <button className="sign-in-button" type="submit">Login</button>
                </form>
            </>
            <div className="sign-in-bottom-container">
                Don't have an account?
                <button className="link-button sign" onClick={onSignUpClick}>Sign up</button>
            </div>
        </div>
    );
};
export default SignIn;