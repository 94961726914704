import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const loadingStatusState: any = {};

const loadingStatusSlice = createSlice({
    name: 'loadingStatus',
    initialState: loadingStatusState,
    reducers: {
        setLoadingStatus(state, action: PayloadAction<any>) {
            let payload = action?.payload || { key: "default", value: false };
            state["default"] = payload.value;
            state[payload.key] = payload.value;
        },
    }
})

export const { setLoadingStatus } = loadingStatusSlice.actions;
export default loadingStatusSlice.reducer;