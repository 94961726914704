// Route Paths
export const LOGIN = "/login";
export const REGISTRATION = "/registration";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const SAMPLE_QUIZ = "/sample-quiz";
export const QUIZ = "/user/:username/quiz";
export const EDITOR = "/editor";
export const HOME = "/user/:username/home";
export const PROFILE = "/user/:username/profile";
export const ACCOUNTSETTINGS = "/user/:username/account-settings";

