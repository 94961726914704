import { FC } from "react";
import './ScorePopup.css';
import Modal from "components/Modal/Modal";
import ConfettiLargeIcon from 'assets/gif/confetti.gif';
interface ScorePopupProps {
    score: any;
    isOpen: boolean;
    onClose: () => void;
    onPlayNext: () => void;
}
const ScorePopup: FC<ScorePopupProps> = ({ score, isOpen, onClose, onPlayNext }) => {
    return <Modal isOpen={isOpen} onClose={onClose} footer={<div className="popup-footer">
        <button className="close-button" onClick={onClose}>Close</button>
        <button className="play-again-button" onClick={onPlayNext}>SignUp</button>
    </div>}>
        <>
            <div className="score-modal-container">
                <div>
                    <div className="result-container">
                        <label className="result-message">{score?.actualScore > 60 ? "Congratulations!" : "Sorry!"}</label>
                        <div className="result-message-container">
                            {score?.actualScore > 60 && <img width={70} height={70} src={ConfettiLargeIcon} alt="confetti"></img>}
                            <label className={`result-label ${score?.actualScore > 60 ? "" : "failed"}`}>{score?.actualScore > 60 ? "You Won !" : "You score is Low"}</label>
                            {score?.actualScore > 60 && <img width={70} height={70} src={ConfettiLargeIcon} alt="confetti"></img>}
                        </div>
                    </div>
                </div>
                <label className="score-details-label">Score Details</label>
                <div className="score-outer-container">
                    <div className="score-container">
                        <div>
                            <label>Your score</label>
                            <span>:</span>
                            <span className={score?.actualScore >= 60 ? "success-span" : "failure-span"}>{score?.actualScore}</span>
                        </div>

                        <div>
                            <label>Bonus points</label>
                            <span>:</span>
                            <span className={score?.actualScore >= 60 ? "success-span" : "failure-span"}>{score?.bonusPoints}</span>
                        </div>
                        <div>
                            <label>Total time taken (in seconds) </label>
                            <span>:</span>
                            <span className={score?.actualScore >= 60 ? "success-span" : "failure-span"}>{score?.timeTaken}</span>
                        </div>
                    </div>
                    {/* <div className="score-container">
                        <div>
                            <label>Oponent's score</label>
                            <span>:</span>
                            <span className="failure-span">66</span>
                        </div>
                        <div>
                            <label>Total questions answered</label>
                            <span>:</span>
                            <span className="success-span">10</span>
                        </div>
                        <div>
                            <label>Total time taken (in seconds) </label>
                            <span>:</span>
                            <span className="success-span">605s</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </>

    </Modal>
}
export default ScorePopup;