import { FC } from "react";
import ReactDOM from 'react-dom';
import './Alert.css';

export enum AlertType {
    OK = 0,
    YESNO = 1,
}

interface AlertProps {
    isOpen: boolean;
    message: string;
    title: string;
    type: AlertType;
    onClose: () => void;
}

const Alert: FC<AlertProps> = ({ isOpen, title, message, type, onClose }) => {
    if (!isOpen) return null;
    let alertParent: any;
    if (document.getElementById('modal-root')) {
        alertParent = document.getElementById('modal-root');
    }
    return ReactDOM.createPortal(
        <div className='alert-overlay' onClick={()=>onClose()}>
            <div className="alert-container" onClick={(e) => e.stopPropagation()}>
                <div className='alert-header'>
                    <label className='alert-title'>{title}</label>
                </div>
                <div className='alert-content'>
                    <>
                        <label>{message}</label>
                    </>
                </div>
                <div className='alert-footer'>
                    {type === AlertType.OK ? <button className="ok-button" onClick={onClose}>OK</button> :
                        <>
                            <button className="close-button" onClick={onClose}>No</button>
                            <button className="ok-button">Yes</button>
                        </>
                    }
                </div>
            </div>
        </div>,
        alertParent
    );
};

export default Alert;
