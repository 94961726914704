import { FC, useEffect, useState } from "react";
interface OptionProps {
    data: IOption,
    index: number,
    questionNumber: number,
    handleOptionChange: (index: number, selected: boolean) => void
}
interface IOption {
    id: string,
    title: string,
    isMarkedAsAnswer: boolean
}
const Option: FC<OptionProps> = ({ data, index, questionNumber, handleOptionChange }) => {
    const [animation, setAnimation] = useState('');

    useEffect(()=>{
        setAnimation('fade-in');
    },[questionNumber]);

    const onContainerAnimationEnd = () => {
        setAnimation('');
    }
    const onOptionChange = (event: any) => {
        handleOptionChange(index, event.target.checked);
    }
    return <div key={data.id} className={`${animation} ${data.isMarkedAsAnswer ? 'option-container option-selected' : 'option-container'}`}
        style={{ animationDelay: `${index * 0.3}s` }} onAnimationEnd={onContainerAnimationEnd} >
        <span>{String.fromCharCode(65 + index)}. </span>
        <input type="radio" id="option" name="option" className="option-checkbox"
            onChange={onOptionChange} checked={data.isMarkedAsAnswer} />
        <label htmlFor="option" className="option-label"> {data.title}</label>
    </div>
}
export default Option;