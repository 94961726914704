import React, { useState, useEffect } from 'react';
import './Profile.css';

// Interface for Quiz Stats
interface QuizStats {
  quizzesTaken: number;
  totalScore: number;
  ranking: string;
}

// Interface for the Profile data
interface UserProfile {
  username: string;
  profilePicture: string;
  bio: string;
  quizStats: QuizStats;
  achievements: string[];
}

// Profile Stats Component
const ProfileStats: React.FC<QuizStats> = ({ quizzesTaken, totalScore, ranking }) => (
  <div className="profile-stats">
    <h3>Quiz Stats</h3>
    <ul>
      <li>Quizzes Taken: {quizzesTaken}</li>
      <li>Total Score: {totalScore}</li>
      <li>Ranking: {ranking}</li>
    </ul>
  </div>
);

// Main Profile Component
interface ProfileProps {
  userData: UserProfile;
}

const Profile: React.FC<ProfileProps> = ({ userData }) => {
  // State to manage profile data
  const [profile, setProfile] = useState<UserProfile>(userData);

  // Function to handle profile edit (can be expanded further)
  const handleEditProfile = () => {
    console.log('Edit profile clicked');
    // Example logic: Could open a form/modal for updating profile
  };

  // If userData changes, update the profile state (useEffect hook)
  useEffect(() => {
    setProfile(userData);
  }, [userData]);

  return (
    <div className="profile-container">
      <div className="profile-header">
        {/* <img
          src={profile?.profilePicture}
          alt={`${profile?.username}'s profile`}
          className="profile-pic"
        /> */}
        <h2>{profile?.username}</h2>
        <p>{profile?.bio}</p>
        <button onClick={handleEditProfile} className="edit-profile-button">
          Edit Profile
        </button>
      </div>
      
      <ProfileStats 
        quizzesTaken={profile?.quizStats?.quizzesTaken}
        totalScore={profile?.quizStats?.totalScore}
        ranking={profile?.quizStats?.ranking}
      />
      
      <div className="profile-achievements">
        <h3>Achievements</h3>
        <ul>
          {profile?.achievements?.map((achievement, index) => (
            <li key={index}>{achievement}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Profile;
