import { Action } from '@reduxjs/toolkit';
import { ThunkAction } from "redux-thunk"
import { API } from 'services/api-config';
import Axios from 'services/axios-interceptor';
import { AppState } from 'store';
import { setIsAuthenticated, setUserDetails } from 'store/slices/auth-slice';

export const loginUser = (userCredentails: UserCredentials, successCallback?: (user: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(API.USER_LOGIN, userCredentails)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    dispatch(setUserDetails(response.data));
                    successCallback && successCallback(response.data);
                }
                else {
                    dispatch(setUserDetails(null));
                    failedCallback && failedCallback();
                }
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };

export const registerUser = (userDetails: any, successCallback?: (response: any) => void, failedCallback?: (error: any) => void): ThunkAction<void, AppState, unknown, Action> =>
    () => {
        Axios.post(API.USER_REGISTER, userDetails)
            .then((response) => {
                successCallback && successCallback(response.data);
            }).catch((error: any) => {
                failedCallback && failedCallback(error);
            })
    };

export const logOut = (successCallback?: (param: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(API.USER_LOGOUT, {})
            .then((response) => {
                dispatch(setUserDetails(null));
                dispatch(setIsAuthenticated(false));
                successCallback && successCallback(response);
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };
export const forgotPassword = (email: any, successCallback?: (user: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(API.FORGOT_PASSWORD, { email: email })
            .then((response) => {
                if (response.status === 200 && response.data) {
                    //dispatch(setUserDetails(response.data));
                    successCallback && successCallback(response.data);
                }
                else {
                    // dispatch(setUserDetails(null));
                    failedCallback && failedCallback();
                }
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };
export const resetPassword = (payload: any, successCallback?: (user: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(API.RESET_PASSWORD, payload)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    //dispatch(setUserDetails(response.data));
                    successCallback && successCallback(response.data);
                }
                else {
                    // dispatch(setUserDetails(null));
                    failedCallback && failedCallback();
                }
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };