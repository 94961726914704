import { FC, useEffect, useRef, useState } from "react";
import './Quiz.css';
import Question from "./components/Question";
import { useAppDispatch, useAppSelector } from "hooks/CustomHooks";
import { getQuizData, getSampleQuiz, submitQuiz } from "store/services/quiz-service";
import { AppRootState, AppState } from "store";
import ScorePopup from "./components/ScorePopup";
import { setQuizData } from "store/slices/quiz-slice";
import { checkAndRequestNewData } from "common/Helper";
import { useNavigate } from "react-router-dom";
import { QUIZ, REGISTRATION } from "route/paths";
import { QuizCategory, QuizTechnology } from "common/enum";

interface QuizProps {
    quizType: QuizCategory;
}

const technologies: any = [
    {
        label: "Javascript",
        value: QuizTechnology.JAVASCRIPT
    },
    {
        label: "Angular",
        value: QuizTechnology.ANGULAR
    },
    {
        label: "Node.js",
        value: QuizTechnology.NODE
    },
    {
        label: "React Js",
        value: QuizTechnology.REACT
    }
];
const Quiz: FC<QuizProps> = ({ quizType }) => {
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const [isShowScorePopup, setIsShowScorePopup] = useState<boolean>(() => false);
    const [isTabActive, setIsTabActive] = useState<boolean>(true);
    const [isCurrentQuestionAnswered, setIsCurrentQuestionAnswered] = useState<boolean>(false);
    const [score, setScore] = useState<any>(null);
    const [selectedSkill, setSelectedSkill] = useState<number>(technologies[1]?.value);
    const [lastSelectedSkill, setLastSelectedSkill] = useState<number>(-1);

    let timeTakenToAnswerRef = useRef<number>(0);
    let totalTimeTakenRef = useRef<number>(0);
    let answersRef = useRef<any>([]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let quizData = useAppSelector((state: AppRootState) => state?.quizReducer?.quizData);

    useEffect(() => {
        if (selectedSkill >= 0) {
            checkAndRequestNewData(selectedSkill === lastSelectedSkill ? quizData : null, dispatch, getSampleQuiz, selectedSkill, (data: any) => {
                dispatch(setQuizData(data));
            }, () => {
                dispatch(setQuizData(null));
            })
            setLastSelectedSkill(selectedSkill);
        }
        else {
            dispatch(setQuizData(null));
        }
    }, [selectedSkill]);

    useEffect(() => {
        if (quizData?.currentQuestionOrder && currentQuestion) {
            if (quizData.currentQuestionOrder !== currentQuestion?.order && quizData?.questions) {
                quizData = { ...quizData, startedAt: Date.now() };
                setCurrentQuestion(quizData.questions[quizData?.currentQuestionOrder]);
            }
            else {
                // do nothing
            }
        }
        else if (quizData && quizData.questions) {
            quizData = { ...quizData, startedAt: Date.now() };
            setCurrentQuestion(quizData?.questions[0]);
        }
    }, [quizData]);

    useEffect(() => {
        // if (!isTabActive) {
        //     alert('Warning !! Switching between tabs during the challenge is not allowed.')
        // }

    }, [isTabActive]);

    const disableRightClick = (e: any) => {
        // e.preventDefault();
        // alert('Right click not allowed');
    };

    useEffect(() => {
        const handleVisibilityChange = (e: any) => {
            e.preventDefault();
            setIsTabActive(!document.hidden);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        document.addEventListener('contextmenu', disableRightClick);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('contextmenu', disableRightClick);
        };
    }, []);

    const onTimerChange = (secondsTaken: number) => {
        timeTakenToAnswerRef.current = secondsTaken;
    }


    const onQuestionAnswered = (options: any) => {
        let answers: any = [];
        options?.forEach((answer: any) => {
            if (answer.isMarkedAsAnswer) {
                answers.push({ id: answer.id, order: answer.order })
                answersRef.current.push({ id: answer.id, order: answer.order });
            }
        });
        setIsCurrentQuestionAnswered(answers?.length > 0);
    }
    const onTimeElapsed = () => {
        onSubmit();
    }
    const onTechnologyChange = (event: any) => {
        const selectedTechnology: any = technologies.filter((technology: any) => technology.value.toString() === event.target.value);
        setSelectedSkill(selectedTechnology[0].value);
    }
    // const onSkipQuestion = () => {
    //     setCurrentQuestion({ ...questions[currentQuestion.order], order: currentQuestion.order + 1 });
    // }

    const onSubmit = () => {
        if (quizData) {
            let tempQuestions: any[] = [...quizData.questions];
            totalTimeTakenRef.current += timeTakenToAnswerRef.current;
            if (currentQuestion?.order < tempQuestions?.length) {
                timeTakenToAnswerRef.current = 0;
                setCurrentQuestion({ ...tempQuestions[currentQuestion.order], order: currentQuestion.order + 1 });
                setIsCurrentQuestionAnswered(false);
                let currentIndex: number = tempQuestions.findIndex((item: any) => item.order === currentQuestion.order);
                if (currentIndex !== -1) {
                    const answers: any = answersRef.current[answersRef.current.length - 1];
                    tempQuestions[currentIndex] = {
                        ...currentQuestion, answers: answers
                    };
                }
                dispatch(setQuizData({ ...quizData, currentQuestionOrder: currentQuestion.order, questions: [...tempQuestions] }));
            }
            else {
                let answeredQuiz = {
                    quizId: quizData.id,
                    answers: answersRef.current,
                    totalTimeTakenInSeconds: totalTimeTakenRef.current
                };
                dispatch(submitQuiz(answeredQuiz, (score: any) => {
                    dispatch(setQuizData(null));
                    setCurrentQuestion(null);
                    setScore(score);
                    setIsShowScorePopup(true);
                }))
            }
        }
    }

    const onScorePopupClose = () => {
        setIsShowScorePopup(false);
        navigate('/');
    }
    const onPlayNext = () => {
        setIsShowScorePopup(false);
        navigate(REGISTRATION);
    }
    return (
        <div className="quiz-container">
            <div className="quiz-main-container">
                <div className="quiz-header">
                    <label className="quiz-type-label">
                        {quizType}
                    </label>
                    {(currentQuestion?.order === 1 || currentQuestion?.order > 10) && <select className="skills-dropdown" value={selectedSkill} onChange={onTechnologyChange} >
                        <option value={-1} disabled selected hidden >Select Your Skill</option>
                        {
                            technologies?.map((technology: any) => (
                                <option key={technology.value} value={technology.value}>
                                    {technology.label}
                                </option>
                            ))
                        }
                    </select>
                    }
                </div>
                {quizData?.questions ?
                    <Question question={currentQuestion}
                        totalQuestions={quizData?.questions?.length | 0}
                        onQuestionAnswered={onQuestionAnswered}
                        onTimeElapsed={onTimeElapsed}
                        onTimerChange={onTimerChange}
                    /> : <></>
                }
            </div>
            <div className="quiz-footer">
                <div className="quiz-footer-content">
                    <button className="skip-button" disabled={isCurrentQuestionAnswered}
                        title={isCurrentQuestionAnswered ? "You cannot skip the answered question" :
                            "You cannot come back and answer this"}
                        onClick={onSubmit}>
                        Skip
                    </button>
                    <button className="submit-button" title={!isCurrentQuestionAnswered ? "Save and Proceed to next question" : "You can proceed only after the question is answered"} onClick={onSubmit} disabled={!isCurrentQuestionAnswered}>
                        {currentQuestion?.order === quizData?.questions?.length ? "Submit" : "Proceed"}
                    </button>
                </div>
            </div>
            <ScorePopup isOpen={isShowScorePopup} score={score}
                onClose={onScorePopupClose} onPlayNext={onPlayNext} />
        </div>
    );
}

export default Quiz;
