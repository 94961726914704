import { useAppDispatch } from "hooks/CustomHooks";
import { FC, useState } from "react";
import { forgotPassword } from "store/services/auth-service";
interface ForgotPasswordProps {

}
const GMAIL_LINK = "https://mail.google.com";
const OUTLOOK_LINK = "https://outlook.office.com/mail";
const YAHOO_LINK = "https://mail.yahoo.com";
const HOTMAIL_LINK = "https://hotmail.com";
const REDIFF_MAIL = "https://mail.rediff.com/";

const ForgotPassword: FC<ForgotPasswordProps> = () => {
    const [email, setEmail] = useState<string>("");
    const [showMailLink, setShowMailLink] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const dispatch = useAppDispatch();

    const validateForm = () => {
        let error: string = ""
        if (email.length === 0) {
            error = "Email Id is required";
        }
        else if (!/\S+@\S+\.\S+/.test(email)) {
            error = "Email address is invalid";
        }
        else {
            //
        }
        setError(error);
        return error;
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError.length === 0) {
            dispatch(forgotPassword(email, (response: any) => {
                if (response.status) {
                    setShowMailLink(true);
                }
                else {
                    setError(response.message);
                }
            }, () => {
                setError('Email Id is Incorrect');
            }));
        };
    }
    const onMailLinkClick = () => {
        window.open("https://mail.google.com", '_blank');
    }

    return (<div className="sign-in-content">
        <>
            {showMailLink ?
                <form className="sign-in-form">
                    <div>
                        <label>Reset password link is succesfully sent to your email, please <button onClick={onMailLinkClick}>go to your email</button> and click on the link to reset your password</label>
                    </div>
                </form> :
                <form className="sign-in-form" onSubmit={handleSubmit} >
                    <h2>Forgot Password</h2>
                    {error?.length > 0 && <div className="input-validation-error">{error}</div>}
                    <input className="sign-in-input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your registered email"
                    />
                    <button className="sign-in-button" type="submit">Send Reset Link</button>
                </form>
            }

        </>
    </div>
    );
}

export default ForgotPassword;



