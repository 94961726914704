import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
    name: 'quiz',
    initialState: {
        quizData: null
    },
    reducers: {
        getQuizData(state, action: PayloadAction<any>) {
            state.quizData = action.payload;
        },
        setQuizData(state, action: PayloadAction<any>) {
            state.quizData = action.payload;
        }
    },
});


export const { getQuizData, setQuizData } = quizSlice.actions;
export const { reducer: quizReducer } = quizSlice;