
export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}
export const setToken = (token: string) => {
    document.cookie = `token=${token}; path=/; max-age=${1 * 60 * 60}; secure; samesite=strict`;
}
export const getToken = () => {
    const match = document.cookie.match(new RegExp('(^| )' + 'token' + '=([^;]+)'));
    return match ? match[2] : null;
}
export const removeToken = () => {
    document.cookie = 'token=; path=/; max-age=0';
}
export function checkAndRequestNewData(stateData: any, dispatch: any, action: (param?: any) => void, ...params: any) {
    if (stateData) {
        if ((Array.isArray(stateData) && stateData.length === 0) ||
            (typeof stateData === 'object' && Object.keys(stateData).length === 0)
            || stateData.length === 0 || stateData === true || stateData === "NoPolicy") {
            dispatch(action(...params));
        }
    }
    else {
        dispatch(action(...params),);
    }
};
export const getKeyFromUrl = (url?: string) => {
    let key = "default";
    if (url && url.trim().length > 0) {
        let splitCharacter = url?.includes('/?') ? '/?' : '?';
        let urlArray = url?.split(splitCharacter)[0]?.split('/');
        if (urlArray) {
            const regex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
            let isNumber: boolean = regex.test(urlArray[urlArray.length - 1]);
            key = isNumber ? `${urlArray[urlArray.length - 2]}` : `${urlArray[urlArray.length - 1]}`;
        }
    }
    return key;

}

