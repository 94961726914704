import { Action } from '@reduxjs/toolkit';
import { ThunkAction } from "redux-thunk"
import { API } from 'services/api-config';
import Axios from 'services/axios-interceptor';
import { AppState } from 'store';
import { setQuizData } from 'store/slices/quiz-slice';

export const getQuizData = (payload: any[], successCallback?: (user: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.GET_QUIZ}`, payload)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    dispatch(setQuizData(response.data));
                    successCallback && successCallback(response.data);
                }
                else {
                    failedCallback && failedCallback();
                }
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };

export const getSampleQuiz = (technology: any, successCallback?: (data: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.get(`${API.GET_SAMPLE_QUIZ}?technology=${technology}`)
            .then((response) => {
                if (response.status === 200 && response.data && response.data.questions?.length > 0) {
                    successCallback && successCallback(response.data);
                }
                else {
                    failedCallback && failedCallback();
                }
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };

export const submitQuiz = (payload: any, successCallback?: (user: any) => void, failedCallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.SUBMIT_QUIZ}`, payload)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    successCallback && successCallback(response.data);
                }
                else {
                    failedCallback && failedCallback();
                }
            }).catch((error: any) => {
                failedCallback && failedCallback();
            })
    };