import React, { useState, FormEvent, FC } from "react";
import "./AccountSettings.css";

interface AccountSettingsState {
  username: string;
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
  errorMessage: string;
}

const AccountSettings:FC = () => {
  const [state, setState] = useState<AccountSettingsState>({
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    errorMessage: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { newPassword, confirmPassword, email, password } = state;

    // Validate password match
    if (newPassword !== confirmPassword) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: "Passwords do not match!",
      }));
      return;
    }

    // Validate email format
    if (!/\S+@\S+\.\S+/.test(email)) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: "Please enter a valid email.",
      }));
      return;
    }

    // Here you would typically send the form data to the backend, for example:
    console.log("Updated Account Settings:", { username: state.username, email, password, newPassword });

    // After a successful update, navigate to a dashboard or another page
  };

  return (
    <div className="account-settings-container">
      <h2>Account Settings</h2>
      {state.errorMessage && <div className="error-message">{state.errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={state.username}
            onChange={handleInputChange}
            placeholder="Enter your username"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={state.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Current Password</label>
          <input
            type="password"
            id="password"
            value={state.password}
            onChange={handleInputChange}
            placeholder="Enter your current password"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={state.newPassword}
            onChange={handleInputChange}
            placeholder="Enter a new password"
          />
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={state.confirmPassword}
            onChange={handleInputChange}
            placeholder="Confirm your new password"
          />
        </div>

        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default AccountSettings;
