import { FC, useEffect, useRef, useState } from 'react';
import './ProfileMenu.css';
import { useNavigate } from 'react-router-dom';
import { PROFILE } from 'route/paths';
import ProfileIcon from 'assets/svg/profile.svg';
import AccountSettingsIcon from 'assets/svg/account-settings.svg';
import LogoutIcon from 'assets/svg/logout.svg';

interface ProfileMenuProps {
    userInitial: string,
    onProfileView: () => void;
    onAccountSettingsView: () => void;
    onLogout: () => void;
}

const ProfileMenu: FC<ProfileMenuProps> = ({
    userInitial,
    onProfileView,
    onAccountSettingsView,
    onLogout
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<any>(null);
    const navigate = useNavigate();

    const handleClickOutside = (event: any) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        onLogout();
        setIsOpen(false);
    }
    const handleProfileView = () => {
        navigate(PROFILE)
    }

    return (
        <div className="profile-menu" ref={menuRef}>
            <button onClick={toggleMenu} className="profile-button">
                {/* <img
                        src="/path-to-profile-pic.jpg"
                        alt="Profile"
                        className="profile-pic"
                    /> */}
                {userInitial}
            </button>
            {isOpen && (
                <ul className="menu-dropdown">
                    <li><button onClick={handleProfileView} ><img width={22} height={22} src={ProfileIcon} />View Profile</button></li>
                    <li><button onClick={onAccountSettingsView} ><img width={22} height={22} src={AccountSettingsIcon} />Account Settings</button></li>
                    <li><button className='sign-out-button' onClick={handleLogout}><img width={22} height={22} src={LogoutIcon} />SIGN OUT</button></li>
                </ul>
            )}
        </div>
    );
};

export default ProfileMenu;
