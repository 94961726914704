import { getToken } from "common/Helper";
import { useAppDispatch } from "hooks/CustomHooks";
import React, { FC, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { forgotPassword, resetPassword } from "store/services/auth-service";

const ResetPassword: FC = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const dispatch = useAppDispatch();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage("Passwords do not match!");
            return;
        }
        dispatch(resetPassword({ token, password }, (data: any) => {
            if (data.success) {
                setMessage("Password reset successful!");
            } else {
                setMessage("Error: " + data.message);
            }
        })
            , (error: any) => {
                setMessage("Something went wrong. Please try again later.");
            })
    };

    return (
        <div className="sign-in-content">
            <form className="sign-in-form" onSubmit={handleSubmit}>
                <h2>Reset Password</h2>
                <div>
                    <input className="sign-in-input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your new password"
                        required
                    />
                </div>
                <div>
                    <input className="sign-in-input"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm your new password"
                        required
                    />
                </div>
                <button className="sign-in-button" type="submit">Reset Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ResetPassword;
