import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: localStorage.getItem('token') || null,
        isAuthenticated: false,
        loading: false,
        error: null,
    },
    reducers: {
        logout(state) {
            state.token = null;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
        },
        setUserDetails(state, action: PayloadAction<any>) {
            state.user = action.payload;
        },
        setIsAuthenticated(state, action: PayloadAction<any>) {
            state.isAuthenticated = action.payload;
        }
    },
});


export const { logout, setUserDetails, setIsAuthenticated } = authSlice.actions;
export const { reducer: authReducer } = authSlice;