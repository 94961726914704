import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { useAppSelector } from 'hooks/CustomHooks';
import { AppRootState } from 'store';
import TopBar from 'components/TopBar/TopBar';
import {
  ACCOUNTSETTINGS, EDITOR, FORGOT_PASSWORD, LOGIN, PROFILE,
  QUIZ, REGISTRATION, RESET_PASSWORD, SAMPLE_QUIZ
} from './route/paths';
import ResetPassword from 'pages/login/ResetPassword';
import Quiz from './pages/quiz/Quiz';
import { LoginPageMode } from 'pages/login/Login';
import FallbackLogo from 'assets/images/logo.png';
import './App.css';
import Loader from 'components/Loader/Loader';
import Profile from 'pages/profile/Profile';
import AccountSettings from 'pages/profile/AccountSettings';

const Home = lazy(() => import('pages/home/Home'));
const Editor = lazy(() => import('pages/editor/Editor'));
const Main = lazy(() => import('pages/main/Main'));
const Login = lazy(() => import('pages/login/Login'));

const App = () => {
  const { user, isAuthenticated } = useAppSelector((state: AppRootState) => state?.authReducer);

  const AuthenticatedRoutes = () => (
    <Routes>
      <Route path={PROFILE} element={<Profile userData={user} />} />
      <Route path={ACCOUNTSETTINGS} element={<AccountSettings/>} />
      <Route path={'/user/:username/home'} element={<Home />} />
      <Route path={QUIZ} element={<Quiz quizType='Self Challenge' />} />
      <Route path={EDITOR} element={<Editor title='Editor' />} />
      <Route path="/" element={<Navigate to={`/user/${user.username}/home`} />} />
    </Routes>
  );

  const GuestRoutes = () => (
    <Routes>
      <Route path={LOGIN} element={<Login mode={LoginPageMode.SignIn} />} />
      <Route path={REGISTRATION} element={<Login mode={LoginPageMode.SignUp} />} />
      <Route path={FORGOT_PASSWORD} element={<Login mode={LoginPageMode.ForgotPassword} />} />
      <Route path={RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={SAMPLE_QUIZ} element={<Quiz quizType='Sample Quiz' />} />
      <Route path="/" element={<Main />} />
      <Route path="*" element={<Login mode={LoginPageMode.SignIn} />} />
    </Routes>
  );

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className='fallback-loader-container'>
            <img className='fallback-logo' src={FallbackLogo} alt="Loading..." />
          </div>
        }
      >
        <BrowserRouter>
          <TopBar isUserLoggedIn={isAuthenticated} />
          <div className='app-content-container'>
            {isAuthenticated ? <AuthenticatedRoutes /> : <GuestRoutes />}
            <Loader />
          </div>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
