export enum DifficultyLevel {
    Short = 1,
    Medium = 2,
    Long = 3,
}
export enum QuizTechnology {
    ANGULAR = 1,
    DOTNET = 2,
    GOLANG = 3,
    JAVA = 4,
    JAVASCRIPT = 5,
    NODE = 6,
    PYTHON = 7,
    REACT = 8,
    VUE = 9,
    WEB_DEVELOPMENT = 10
}
export enum QuizCategory {
    SAMPLE_QUIZ = 0,
    SOLO_QUIZ = 1,
    ONE_TO_ONE_QUIZ = 2,
    ONE_TO_MANY_QUIZ = 3,
    INTRA_TEAM_QUIZ = 4,
    TEAM_VS_TEAM_QUIZ = 5,
    TEAM_VS_MANY_TEAMS_QUIZ = 6,
}