import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { FORGOT_PASSWORD, LOGIN, REGISTRATION, RESET_PASSWORD } from "route/paths";
import './Login.css';
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

interface LoginPageProps {
    mode: LoginPageMode;
}
export enum LoginPageMode {
    SignIn,
    SignUp,
    ForgotPassword,
    ResetPassword,
}

const Login: FC<LoginPageProps> = ({ mode }) => {
    const [pageMode, setPageMode] = useState<LoginPageMode>(mode);

    const navigate = useNavigate();
    const handleSignUpClick = () => {
        setPageMode(LoginPageMode.SignUp);
        navigate(REGISTRATION);
    }
    const handleSignInClick = () => {
        setPageMode(LoginPageMode.SignIn);
        navigate(LOGIN);
    }
    const handleForgotPasswordClick = () => {
        setPageMode(LoginPageMode.ForgotPassword);
        navigate(FORGOT_PASSWORD);
    }

    const getPage = () => {
        switch (pageMode) {
            case LoginPageMode.SignIn:
                return <SignIn onSignUpClick={handleSignUpClick}
                    onForgotPasswordClick={handleForgotPasswordClick} />
            case LoginPageMode.SignUp:
                return <SignUp onSignInClick={handleSignInClick} />
            case LoginPageMode.ForgotPassword:
                return <ForgotPassword/>
            case LoginPageMode.ResetPassword:
                return <ResetPassword />
            default:
                return <SignIn onSignUpClick={handleSignUpClick}
                    onForgotPasswordClick={handleForgotPasswordClick} />

        }
    }
    return (
        <div className="sign-in-main-container">
            <div className="login-page-content">
                <div className="sign-in-left-container">
                </div>
                {getPage()}
            </div>
        </div >
    );
};
export default Login;