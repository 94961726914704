import { FC, useEffect, useState } from "react";
import './TopBar.css';
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import Logo from 'assets/svg/logo.svg';
import { useAppDispatch, useAppSelector } from "hooks/CustomHooks";
import { logOut } from "store/services/auth-service";
import { removeToken } from "common/Helper";
import { AppRootState, AppState } from "store";
import { LOGIN, PROFILE } from "route/paths";
import { useNavigate } from "react-router-dom";
// import { LOGIN } from "route/paths";
interface TopBarProps {
    isUserLoggedIn: boolean;
}
const TopBar: FC<TopBarProps> = ({ isUserLoggedIn }) => {
    let user = useAppSelector((state: AppRootState) => state?.authReducer?.user);
    const [userInitial, setUserInitial] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        console.log("user", user);
        if (user?.username) {
            user.username.charAt(0);
            setUserInitial(user.username.charAt(0).toUpperCase());
        }
    }, [user]);


    const handleProfileView = () => {
        navigate(`/user/${user.username}/profile`);

    }
    const onAccountSettingsView = () => {
        navigate(`/user/${user.username}/account-settings`);

    }
    const handleLogout = () => {
        dispatch(logOut((response: any) => {
            console.log("res", response);
            if (response.status === 200) {
                localStorage.removeItem('token');
                removeToken();
                navigate(LOGIN);
            } else {
                console.error('Logout failed');
            }
        }, () => {
           //
        }));
    }

    return <div className="topbar-container">
        <div className="topbar-left-container">
            <div className="logo-container">
                <img className="topbar-logo" src={Logo} />
            </div>
        </div>
        <div className="topbar-right-container">
            {isUserLoggedIn &&
                <ProfileMenu userInitial={userInitial} onProfileView={handleProfileView} onAccountSettingsView={onAccountSettingsView}
                    onLogout={handleLogout} />
            }
        </div>
    </div>
}
export default TopBar;